import Head from 'next/head';

const Custom404 = () => (
  <>
    <Head>
      <title>БериБар: Не удалось найти страницу.</title>
      <link rel="icon" href="/favicon.ico" />
      <meta property="og:type" content="website" />
      <meta property="og:site_name" content="БериБар: Ищи здесь, Покупай по своим контрактам" />
    </Head>
    <div className="error__wrapper">
      <div>
        <h1 className="error__title">404</h1>
        <div className="error__text-wrapper">
          <h2 className="error__text">Не удалось найти страницу.</h2>
        </div>
      </div>
    </div>
  </>
);

export default Custom404;
